.sidebar {
    @media (min-width: @screen-sm-min) {
      margin-top: -140px;
    }
    @media (min-width: @screen-md-min) {
      padding-top: @cal-widget-height;
      margin-top: 0;
    }
    color: @gray-base;
    font-size: 16px;
    .block-line {
        border-top: 2px dashed @mt-pink;
    }
    .block {
        padding-top: 20px;
        margin-bottom: 20px;
        .header {
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 8px;
        }
        ul.main {
            display: block;
            padding: 0;
            margin: 0;
            li {
                padding: 0;
                margin: 0 0 8px 0;
                display: block;
                list-style: none;
                a {
                    color: @gray-base;
                }
                &.selected {
                  a {
                    color: @mt-pink-medium;
                  }
                }
                &.sub {
                  font-style: italic;  
                  padding-top: 8px;
                  ul {
                    padding: 0 0 0 0;
                    margin: 5px 0 5px 0;
                  }
                }
            }
        }
        ul.links {
            font-size: 14px;
            display: block;
            margin: 0;
            padding: 0;
            li {
                list-style: none;
                line-height: 1.2em;
                margin-bottom: .4em;
            }
        }
    }
}
