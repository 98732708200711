.footer {
    .container {
        background: @body-bg;
        padding-top: 60px;
        padding-bottom: 16px;
    }

    .navbar-nav {
        display: none;
        @media (min-width: @screen-sm-min) {
            display: block;
        }
        font-weight: bold;
        font-size: 12px;
        padding-top: 12px;
        margin-right: 16px;
        a {
            color: @gray-base;
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            margin-right: 8px;
            @media (min-width: @screen-md-min) {
                margin-right: 18px;
            }
            &:active,
            &:hover {
                text-decoration: underline;
                background: none;
            }
        }
        li {
            &:last-child {
                a {
                    margin-right: 0;
                }
            }
        }
    }

    .delimiter {
        .pull-left();
        display: none;
        @media (min-width: @mt-iphone-6-width) {
            display: block;
        }
        background: @gray-lighter;
        width: 1px;
        height: 42px;
        text-indent: 2px;
        overflow: hidden;
        margin: -3px 12px 0;
        @media (min-width: @screen-md-min) {
            margin: -3px 18px 0;
        }
        border-top: 1px solid #e8e8e4;
        border-bottom: 1px solid #e8e8e4;
    }

    .infobox {
        .pull-left();
        line-height: 1.05;
        font-size: 10px;
        color: @gray-light;
        padding-top: 4px;
        @media (min-width: @mt-iphone-6-width) {
            width: 160px;
        }
        @media (min-width: @mt-iphone-6-width) {
            width: 160px;
        }
        @media (min-width: @screen-sm-min) {
            width: 188px;
        }
    }

    .infobox-copy {
        min-height: 44px;
        @media (min-width: @mt-iphone-6-width) {
            width: 160px;
        }
        @media (min-width: @mt-iphone-6-plus-width) {
            width: 176px;
        }
        @media (min-width: @screen-sm-min) {
            padding-left: 42px;
            width: 230px;
            background: url(../images/footer-copy.png) 0 2px no-repeat;
        }
    }

    .footer-logo {
        display: none;
        @media (min-width: @screen-md-min) {
            display: block;
        }
        .pull-right();
        height: 50px;
        width: 94px;
        background: url(../images/logo-small.png) left top no-repeat;
        text-indent: 150%;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        line-height: 1;
        .footer-logo-head {
            font-size: 28px;
            font-weight: bold;
            margin: -4px 0 0;
        }
        .footer-logo-sub {
            font-size: 11px;
            color: @mt-pink-light;
        }
        @media (min-width: @screen-lg-min) {
            padding-left: 100px;
            text-indent: 0;
            width: 225px;
            white-space: normal;
        }
    }

    .footer-vk {
        display: none;
        @media (min-width: @screen-md-min) {
            display: inline-block;
        }
        background: url(../images/footer-vk-small.png) left top no-repeat;
        width: 38px;
        height: 38px;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        text-indent: 150%;
        @media (min-width: @screen-lg-min) {
            background-image: url(../images/footer-vk.png);
            width: 130px;
        }
        &:active,
        &:hover {
            background-image: url(../images/footer-vk-active-small.png);
            @media (min-width: @screen-lg-min) {
                background-image: url(../images/footer-vk-active.png);
            }
        }
    }
    .mt-friends {
        .mt-block-header {
            margin-top: .2em;
            margin-bottom: @grid-gutter-width;
        }
        margin-bottom: 32px;
        .mt-friend {
            margin-bottom: (@grid-gutter-width / 2);
            float: none;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            margin-right: -4px;
            img { display: inline-block; }
        }
    }
}
