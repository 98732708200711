
.mt-pink {
    color: @mt-pink;
}
.mt-pink-dark {
    color: @mt-pink-dark;
}
.mt-pink-medium {
  color: @mt-pink-medium;
}
.mt-blue {
    color: @mt-blue;
}
.mt-blue-dark {
    color: @mt-blue-dark;
}
.mt-content-delimiter {
  border-bottom: 1px dashed @mt-pink-dark;
  margin-top: 32px;
  margin-bottom: 32px;
  height: 0;
  line-height: 0;
}
.mt-content {
  position: relative;
}
.mt-left-side {
    @media (min-width: @screen-sm-min) {
      width: @sidebar-width;
      float: left;
    }
}
.mt-right-side {
    @media (min-width: @screen-sm-min) {
      float: right;
      width: 500px;
    }
    @media (min-width: @screen-md-min) {
      width: 720px;
    }
    @media (min-width: @screen-lg-min) {
      width: 920px;
    }
}
.mt-games {
    margin-top: 14px;
    width: 160px;
    padding-left: 60px;
    height: 70px;
    background: url(../images/games.png) left top no-repeat;
    padding-top: 14px;
    h3 {
        color: @mt-black;
        font-weight: bold;
        font-size: 14px;
        margin: 0 0 .3em 0;
    }
    p {
        font-size: 13px;
    }
}
.mt-evo {
    margin-top: 18px;
    width: 185px;
    padding-left: 70px;
    height: 70px;
    background: url(../images/evo.png) left top no-repeat;
    padding-top: 4px;
    h3 {
        color: @mt-black;
        font-weight: bold;
        font-size: 14px;
        margin: 0 0 .3em 0;
    }
    p {
        font-size: 13px;
    }
}

.mt-tales {
    margin-top: 414px;
    width: 180px;
    padding-left: 70px;
    height: 80px;
    background: url(../images/tales-sm.png) left top no-repeat;
    padding-top: 0px;
    h3 {
        color: @mt-black;
        font-weight: bold;
        font-size: 14px;
        margin: 0 0 .3em 0;
    }
    p {
        font-size: 13px;
    }
}

.mt-tales-main {
    margin-top: 14px;
    width: 180px;
    padding-left: 70px;
    height: 80px;
    background: url(../images/tales-sm.png) left top no-repeat;
    padding-top: 0px;
    h3 {
        color: @mt-black;
        font-weight: bold;
        font-size: 14px;
        margin: 0 0 .3em 0;
    }
    p {
        font-size: 13px;
    }
}


.mt-consulting {
    margin-top: 14px;
    width: 160px;
    padding-left: 60px;
    height: 70px;
    background: url(../images/jurist.png) left top no-repeat;
    padding-top: 5px;
    h3 {
        color: @mt-black;
        font-weight: bold;
        font-size: 14px;
        margin: 0 0 .3em 0;
    }
    p {
        font-size: 13px;
    }
}

.mt-downloads {
    margin-top: 0px;
    width: 160px;
    padding-left: 60px;
    height: 70px;
    background: url(../images/download_ico.png) left top no-repeat;
    padding-top: 0px;
    h3 {
        color: @mt-black;
        font-weight: bold;
        font-size: 14px;
        margin: 0 0 .3em 0;
    }
    p {
        font-size: 13px;
    }
}



.mt-vertical-block {
    margin-bottom: 36px;
}
.mt-240-right-container {
    .mt-240-right-content {
        @media (min-width: @screen-md-min) {
            padding-right: 240 + @grid-gutter-width;
            float: left;
        }
        width: 100%;
    }
    .mt-240-right {
        width: 100%;
        @media (min-width: @screen-md-min) {
            margin-left: -240px;
            width: 240px;
            float: left;
        }
        .mt-promo {
            width: 240px;
            margin-bottom: 22px;
        }
    }
}

.mt-article {
    h2 {
        font-weight: bold;
        font-size: 20px;
        margin: 0 0 12px 0;
        color: #52a9b6;
        a {
            color: #52a9b6;
        }
    }
    img {
        margin: 12px 0;
        border: 1px solid transparent;
        &.img-bordered {
            border-color: #c3c4c2;
        }
    }
    .mt-img-description {
        padding: 12px 0 0 20px;
        color: #999999;
        font-style: italic;
        font-size: 11px;
        margin-bottom: 1.5em;
        a {
            color: #999999;
        }
    }
    .mt-article-description {
        font-family: 'PT Serif', serif;
        line-height: 1.3em;
        font-size: 14px;
        p {
            margin-bottom: 12px;
        }
        img{
            margin-top: 0;
            margin-bottom: 28px;
            &.pull-left {
                margin-right: 28px;
            }
            &.pull-right {
                margin-left: 28px;
            }
        }
        margin-bottom: 18px;
    }
    .mt-article-meta {
      .mt-author-title {
        font-weight: bold;
        color: @mt-pink-dark;
      }
      .mt-author-name {
        font-style: italic;
        margin-right: 4px;
      }
      .glyphicon { top: 3px; }
      .mt-article-source {
        font-size: 12px;
        .mt-source-title {
          font-weight: bold;
        }
      }
    }
    h3.mt-calendar-header {
      font-size: 18px;
      margin-top: 0;
      font-weight: bold;
      a {
        color: @gray-base;
        .mt-calendar-header-count {
          color: @mt-pink-medium;
          font-size: 28px;
        }
      }
    }
    .mt-article-content {
      font-family: 'PT Serif', serif;
      font-size: 14px;
      line-height: 1.5em;
      p, .mt-quote, .mt-files {
        margin-bottom: 24px;
      }
      h3 {
        font-size: 18px;
        font-weight: bold;
        color: @gray-base;
      }
      .mt-article-illustrations {
        h4 {
          font-weight: bold;
          font-size: 16px;
        }
      }
      .mt-files {
        h4 {
          font-weight: bold;
          font-size: 16px;
        }
        img {
          margin-right: 12px;
        }
        .mt-file-link {
          margin-top: 22px;
        }
      }
      .mt-quote {
        border-left: 6px solid @mt-pink-medium;
        padding-left: 24px;
        padding-top: .2em;
        padding-bottom: .2em;
        p:last-child {
          margin-bottom: 0;
        }
        h4 {
          font-weight: bold;
          font-size: 14px;
        }
        ol {
          padding-top: 8px;
          counter-reset: item;
          padding-left: 0;
        }
        ol li { display: block; margin-bottom: 8px; padding-top: 8px; border-top: 1px dashed @mt-pink-dark; }
        ol li:last-child { margin-bottom: 0; }
        ol li:before {
            content: counter(item) ". ";
            counter-increment: item;
        }
        ol.mt-number-color-pink-dark li:before {
          color: @mt-pink-dark;
        }
        ol.mt-number-bold li:before {
          font-weight: bold;
        }
      }
      .mt-quote-blue-dark {
        border-left-color: @mt-blue-dark;
        ol li { border-top: 1px dashed @mt-blue-dark; }
      }
      .mt-quote-large {
        font-style: italic;
        line-height: 1.5em;
        font-size: 18px;
      }
    }
    .mt-article-info {
        border-left: 6px solid @mt-pink-medium;
        padding: 8px 0 1px 24px;
        h3 {
            margin-top: 0;
            color: @mt-pink-medium;
            font-weight: bold;
            font-size: 16px;
        }
        margin-bottom: 24px;
    }
    margin-bottom: 36px;
}

.mt-article-large {
    h2 {
        font-size: 26px;
    }
}

.mt-article-main {
    h2 {
        font-size: 34px;
        margin: 0 0 14px 0;
    }
    .mt-article-description {
        font-size: 16px;
    }
    .mt-article-description-regular {
        font-size: 14px;
        line-height: 1.5em;
    }
}

.mt-article-highlighted {
    h2 {
        color: @mt-pink-medium;
        a {
            color: @mt-pink-medium;
        }
    }
}

.mt-article-meta {
    .mt-article-meta-link {
        margin-bottom: .3em;
    }
    .mt-article-meta-category {
        a {
            font-size: 0.85em;
            color: #a6a5a2;
        }
    }
}

.mt-search-form {
    .mt-search-form-text {
        display: block;
        width: 100%;
        padding: 8px 40px 8px 8px;
        height: 40px;
        background: transparent;
        border: 1px solid #bcbcb7;
        outline: none;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        &:focus {
            border-color: @mt-pink-dark;
        }
    }
    .mt-search-form-submit {
        float: right;
        display: block;
        border: none;
        background: url(../images/sidebar-search.png) center center no-repeat;
        position: relative;
        height: 40px;
        width: 40px;
        margin-top: -40px;
    }
}

.mt-block-header {
    font-size: 20px;
    h2, h3, .mt-split, .mt-link {
        float: left;
    }
    h2, h3 {
        margin: 0;
        color: @mt-black;
        font-weight: bold;
        font-size: 1em;
        a {
            color: @mt-black;
        }
    }
    .mt-split {
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        text-indent: 150%;
        width: 0;
        height: .7em;
        border-left: 1px solid #b9b9b6;
        border-right: 1px solid #dddeda;
        margin: .25em .6em 0;
    }
    .mt-block-link {
        font-size: .7em;
        margin-top: .4em;
    }
}

.mt-hr {
    border-top: 2px dashed #52a9b6;
    display: block;
    border-bottom: none;
    border-left: none;
    border-right: none;
}

.page-container {
  position: relative;
  z-index: 1;
  background: @body-bg;
  padding-bottom: 32px;
}

.mt-breadcrumbs {
    font-size: 12px;
    margin-bottom: 24px;
    ul {
        display: block;
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            display: inline-block;
            border-right: 1px solid #d0d0ce;
            padding-right: 8px;
            margin-right: 6px;
            &:last-child {
                padding-right: none;
                margin-right: none;
                border-right: none;
            }
        }
    }
}

.btn-mt {
    font-weight: bold;
    .button-variant(#fff; #f791a9; transparent);
    #gradient > .vertical(#f791a9, #d16a82);
    &:hover {
        .button-variant(#fff; #6bd2e8; transparent);
        #gradient > .vertical(#6bd2e8, #02b0ed);
    }
}

.mt-paging {
  display: inline-block;
  list-style: none;
  font-size: 16px;
  font-weight: bold;
  li {
    display: inline-block;
    vertical-align: top;
    a, &.mt-paging-sel {
      margin: 0 2px;
      display: inline-block;
      padding: 0 5px 8px;
      min-width: 36px;
      border-bottom: 3px solid @gray-lighter;
    }
    a {
      &:hover {
        border-bottom-color: @brand-primary;
        text-decoration: none;
        color: @brand-primary;
      }
    }
    &.mt-paging-sel {
      border-bottom-color: @mt-pink-medium;
    }
    &.mt-paging-last {
      a {
        margin-left: 16px;
      }
    }
    &.mt-paging-first {
      a {
        margin-right: 16px;
      }
    }
    &.mt-paging-prev, &.mt-paging-next {
      position: relative;
      top: -2px;
      a {
        width: 32px;
        height: 32px;
        border-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-indent: 200%;
      }
    }
    &.mt-paging-prev {
      background: url(../images/paging-left.png) center center no-repeat;
      margin-right: 36px;
    }
    &.mt-paging-next {
      background: url(../images/paging-right.png) center center no-repeat;
      margin-left: 36px;
    }
  }
}
