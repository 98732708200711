.mt-cal-widget {
  .mt-cal-title {
    font-size: 22px;
    line-height: 26px;
    font-weight: bold;
    color: @mt-blue;
    a {
      color: @mt-pink;
    }
    margin: 0 0 14px 0;
    @media (min-width: @screen-md-min) {
      width: @sidebar-width;
      font-size: 16px;
      line-height: 18px;
      margin: -5px -5px 0 0;
      text-align: right;
      position: absolute;
      left: 0;
    }
  }
  @media (min-width: @screen-md-min) {
    height: @cal-widget-height;
  }
  .mt-cal-part {
    margin-bottom: 32px;
    overflow: hidden;
    @media (min-width: @screen-md-min) {
      float: left;
      width: 25%;
      margin-right: 1%;
      &:last-child {
        width: 26%;
        margin-right: 0;
      }
    }
    @media (min-width: @screen-lg-min) {
      width: 20%;
      &:last-child {
        width: 21%;
      }
    }
    transition: width .5s ease;
  }
  .mt-cal-desc {
    font-size: 14px;
    color: @gray-base;
    position: relative;
    left: 0;
    transition: left .5s ease;
    left: 28px;
    @media (min-width: @screen-md-min) {
      left: 0;
      font-size: 12px;
    }
    @media (min-width: @screen-lg-min) {
      font-size: 14px;
    }
  }
  .mt-cal-container {
    position: relative;
    overflow: hidden;
    margin-bottom: 8px;
    padding: 0 28px;
    .mt-cal-right,
    .mt-cal-left {
      display: block;
      width: 11px;
      height: 13px;
      content: "";
      position: absolute;
      text-indent: 150%;
      overflow: hidden;
      white-space: nowrap;
      top: 5px;
      z-index: 2;
    }

    .mt-cal-list {
      display: block;
      float: left;
      white-space: nowrap;
      padding: 0 1px 0 0 ;
      left: 0;
      cursor: default;
      transition: left .5s ease;
      height: 23px;
      position: relative;
      overflow: hidden;
      li {
        display: inline-block;
        list-style: none;
        margin: 0 -1px 0 0;
        a {
          font-size: 10px;
          text-align: center;
          display: inline-block;
          width: 20px;
          height: 23px;
          line-height: 25px;
          border-radius: 3px;
          color: #fff;
          text-decoration: none;
        }
      }
    }

    .mt-cal-left {
      left: 3px;
    }
    .mt-cal-right {
      right: 3px;
    }
    &:before {
      z-index: 1;
      display: block;
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 28px;
      bottom: 0;
      #gradient.horizontal(rgba(250,251,247,1), rgba(250,251,247,0), 65%, 100%);
    }
    &:after {
      display: block;
      position: absolute;
      content: "";
      right: 0;
      top: 0;
      width: 28px;
      bottom: 0;
      #gradient.horizontal(rgba(250,251,247,0), rgba(250,251,247, 1), 0%, 35%);
    }
    @media (min-width: @screen-md-min) {
      .mt-cal-right,
      .mt-cal-left {
        display: none;
      }
      .mt-cal-list {
        cursor:pointer;
      }
      padding: 0;
      &:before {
        display: none;
      }
    }
  }
  .mt-cal-selected {
    @media (min-width: @screen-md-min) {
      width: 47%;
      &:last-child {
        width: 48%;
      }
    }
    @media (min-width: @screen-lg-min) {
      width: 57%;
      &:last-child {
        width: 58%;
      }
    }
    .mt-cal-desc {
      left: 28px;
    }
    .mt-cal-container{
      .mt-cal-list {
        cursor: default;
      }
      .mt-cal-right,
      .mt-cal-left {
        display: block;
      }
      padding: 0 28px;
      &:before {
        display: block;
      }
    }
  }
  .mt-cal-pink {
    .mt-cal-left {
      background: url(../images/calendar-left-pink.png);
    }
    .mt-cal-right {
      background: url(../images/calendar-right-pink.png);
    }
    .mt-cal-list {
      a {
        #gradient.vertical(#ffcbcb, #ff9eb5);
        &:hover {
          #gradient.vertical(#ffb7b8, #ff8aa6);
        }
      }
    }
  }
  .mt-cal-green {
    .mt-cal-left {
      background: url(../images/calendar-left-green.png);
    }
    .mt-cal-right {
      background: url(../images/calendar-right-green.png);
    }
    .mt-cal-list {
      a {
        #gradient.vertical(#cae5ac, #a5bd8b);
        &:hover {
          #gradient.vertical(#cde8b0, #86ac5c);
        }
      }
    }
  }
  .mt-cal-blue {
    .mt-cal-left {
      background: url(../images/calendar-left-blue.png);
    }
    .mt-cal-right {
      background: url(../images/calendar-right-blue.png);
    }
    .mt-cal-list {
      a {
        #gradient.vertical(#a7e7ec, #80d1e7);
        &:hover {
          #gradient.vertical(#b2e7fd, #5fb4cb);
        }
      }
    }
  }
}
