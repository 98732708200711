.mt-paint-list {
  img {
    border: 1px solid #c3c4c2;
    margin-bottom: 28px;
    &:hover {
      border-color: #52a9b6;
      -webkit-box-shadow: 0px 0px 0px 2px #52a9b6;
      -moz-box-shadow: 0px 0px 0px 2px #52a9b6;
      box-shadow: 0px 0px 0px 2px #52a9b6;
    }
  }
}