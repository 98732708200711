@mt-header-gradient-start: #fadcca;
@mt-header-gradient-end: #ffcbcb;

#page-header {
    height: 48px;
    margin-bottom: 32px;
    #header-share {
        .pull-left();
        margin: 8px 0 0 0;
        .a2a_svg {
            border-radius: 5px;
        }
    }
    #gradient.vertical(@mt-header-gradient-start, @mt-header-gradient-end);
    .header-logo {
        margin: 8px 0 0 15px;
        padding: 0;
        width: 92px;
        height: 33px;
        background: url(../images/logo-small.png) left top no-repeat;
        position: relative;
        text-indent: 150%;
        white-space: nowrap;
        overflow: hidden;
        h1 {
            height: 33px;
            padding: 0;
            margin: 0;
        }
    }
    .navbar-toggle {
        .icon-bar {
            background: @mt-pink-dark;
        }
        &:hover {
            background-color: @mt-pink;
        }
    }
    #navbar {
        background: @mt-header-gradient-start;
        @media (min-width: @screen-sm-min) {
            background: transparent;
        }
        .navbar-nav {
            a {
                color: @mt-pink-dark;
                font-size: 14px;
                font-weight: bold;
                &:hover {
                    background: @mt-header-gradient-end;
                }
            }
        }
        .mt-right-side {
            margin-right: 15px;
        }
        @media (min-width: @screen-sm-min) {
            .navbar-main {
                padding: 18px 0 0;
                a {
                    padding-top: 0;
                    padding-bottom: 0;
                    padding-left: 0;
                    padding-right: 0;
                    margin-right: 16px;
                    &:active,
                    &:hover {
                        text-decoration: underline;
                        background: none;
                    }
                }
            }
            .navbar-share {
                padding: 8px 0 0;
                a {
                    padding-top: 0;
                    padding-bottom: 0;
                    padding-left: 0;
                    padding-right: 0;
                    margin-right: 7px;
                    &:active,
                    &:hover {
                        background: none;
                    }
                }
            }
            .navbar-menu {
                padding: 0;
                a {
                    height: 48px;
                    padding-top: 18px;
                    padding-left: 0;
                    padding-right: 0;
                    margin-left: 16px;
                    &:active,
                    &:hover {
                        text-decoration: underline;
                        background: none;
                    }
                    /* Menu items */
                    @media (min-width: @screen-md-min) {
                        &.subscribe {
                            padding-left: 32px;
                            background: url(../images/header-subscribe.png) left center no-repeat;
                            &:hover {
                                background-image: url(../images/header-subscribe-active.png);
                            }
                        }
                        &.register {
                            padding-left: 36px;
                            background: url(../images/header-register.png) left center no-repeat;
                            &:hover {
                                background-image: url(../images/header-register-active.png);
                            }
                        }
                        &.login {
                            padding-left: 32px;
                            background: url(../images/header-login.png) left center no-repeat;
                            &:hover {
                                background-image: url(../images/header-login-active.png);
                            }
                        }
                    }
                }
            }
        }
    }
}

#menu-header {
    margin-bottom: 30px;
    .logo {
        display: inline-block;
        width: 185px;
        padding-top: 75px;
        background: url(../images/logo-large.png) left top no-repeat;
        .l1 {
            font-weight: bold;
            font-size: 43px;
            line-height: 43px;
        }
        .l2 {
            color: @mt-pink-light;
            font-size: 12px;
        }
        &:hover {
            text-decoration: none;
        }
    }
    .contest {
        .title {
            margin: .85em 0 .7em;
            font-weight: bold;
            font-size: 16px;
            @media (min-width: @screen-sm-min) {
                font-size: 20px;
            }
            @media (min-width: @screen-md-min) {
                font-size: 14px;
            }
            @media (min-width: @screen-lg-min) {
                font-size: 20px;
            }
        }
        .description {
            font-size: 12px;
            @media (min-width: @screen-sm-min) {
                font-size: 14px;
            }
            @media (min-width: @screen-md-min) {
                font-size: 10px;
            }
            @media (min-width: @screen-lg-min) {
                font-size: 14px;
            }
        }
    }
    .menu-item {
        a {
            position: relative;
            z-index: 1;
            display: inline-block;
            margin-bottom: 24px;
            .title {
                font-size: 18px;
                color: @mt-blue;
                font-weight: bold;
                display: block;
                margin-bottom: 3px;
            }
            .description {
                color: @mt-pink-light;
                border-bottom: 1px solid transparent;
                font-size: 12px;
            }
            &:hover {
                text-decoration: none;
            }
            @media (min-width: @screen-sm-min) {
                padding-top: 102px;
                &.forum {
                    background: url(../images/header-menu-forum.png) 0 0 no-repeat;
                }
                &.postcards {
                    background: url(../images/header-menu-postcards.png) -5px 22px no-repeat;
                }
                &.announcements {
                    background: url(../images/header-menu-announcements.png) 0 0 no-repeat;
                }
                &.coloring-books {
                    background: url(../images/header-menu-coloring-books.png) 0 8px no-repeat;
                }
                &.tales {
                    background: url(../images/header-menu-tales.png) 0 8px no-repeat;
                }                
            }
        }
    }
}
