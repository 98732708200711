body.branding {
  .page-container {
    margin-top: 240px;
    padding-top: 40px;
    -webkit-border-top-left-radius: 30px;
    -moz-border-radius-topleft: 30px;
    border-top-left-radius: 30px;
    -webkit-border-top-right-radius: 30px;
    -moz-border-radius-topright: 30px;
    border-top-right-radius: 30px;
  }
  a.branding-link {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    text-indent: -100%;
  }
}

body.branding-01 {
  background:
    url(../images/logo-large.png) center 160px no-repeat,
    url(../images/vendor/branding/01/bg.gif) center top repeat;
}