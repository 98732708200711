.mt-list-block {
  position: relative;
  .mt-article-image {
    display: block;
    margin-bottom: 14px;
    @media (min-width: @screen-sm-min) {
      position: absolute;
      left: 0;
      top: 0;
    }
    img {
      border: 1px solid transparent;
      margin: 0;
      &.img-bordered {
        border-color: #c3c4c2;
      }
    }
  }
  .mt-article {
    margin-bottom: 0;
  }
  margin-bottom: 32px;
}

.mt-link {
  @media (min-width: @screen-sm-min) {
    padding-left: 120px;
  }
  min-height: 40px;
}

.mt-company {
  @media (min-width: @screen-sm-min) {
    padding-left: 120px;
  }
  min-height: 100px;
}

.mt-paint {
  @media (min-width: @screen-sm-min) {
    .mt-article-image {
      width: 160px;
    }
    padding-left: 186px;
    min-height: 166px;
  }
  @media (min-width: @screen-lg-min) {
    .mt-article-image {
      width: auto;
    }
    padding-left: 240px;
    min-height: 220px;
  }
}

.mt-cal-item {
  @media (min-width: @screen-sm-min) {
    .mt-article-image {
      width: auto;
    }
    padding-left: 170px;
    min-height: 150px;
  }
}